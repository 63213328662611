import React, { FC } from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
} from 'react-admin';


export const ListComponent: FC = (props) => {
  return <List {...props}  perPage={100}>
    <Datagrid>
      <TextField source="value" />
      <TextField source="description" />
      <ShowButton />
    </Datagrid>
  </List>
};
