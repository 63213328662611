import React, { FC } from 'react';
import {
    TextField,
    Show,
    TabbedShowLayout,
    Tab,
    BooleanField,
    useGetOne,
    FunctionField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(
  {
      chip: { margin: 4, cursor: 'inherit' },
  },
  { name: 'MyChipField' }
);
export const ShowComponent:FC<{id?:string, className?: string}> = (props) => {
  const classes = useStyles(props);
  const { data: user } = useGetOne('users', props.id || '');
  const scopes = user?.scopes?.map((scope:string) => (
    <Chip
      key={scope}
      className={classes.chip}
      label={scope}
    />
  ));
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Personal Info">
          <BooleanField source="blocked"/>
          <TextField source="id" />
          <TextField source="account" />
          <TextField source="username" />
          <TextField source="email" />
          <BooleanField source="email_verified" />
          <FunctionField 
            label="API Permissions"
            render={() => (scopes)}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};