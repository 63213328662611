import React, { FC } from 'react';
import {
  BooleanInput,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  CheckboxGroupInput,
  useGetList,
  useGetOne
} from 'react-admin';
import styled from 'styled-components';


const Span = styled.span`
  width: 250px;
  font-weight:900;
`;
export const EditComponent: FC<{id?: string}> = (props) => {
  const { ids: scopes} = useGetList('scopes');
  const { data: user } = useGetOne('users', props.id || '');
  const scopeChoices: {id:string, value:string, text: string}[] = (scopes as string[])?.map(scope => ({
    id: scope,
    value: scope,
    text: scope.replace("api", "API")
          .replaceAll(":", " ")
          .replaceAll("-", " ")
          .replaceAll("_", " ")
          .split(" ")
          .map(text => `${text.charAt(0).toUpperCase()}${text.slice(1)}`).join(" ")
  })) || [];
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="User Information">
          <BooleanInput source="blocked" />
          <BooleanInput source="email_verified" />
          <TextInput source="account" />
          <TextInput source="username" />
          <TextInput source="email" type="email" />
          <TextInput source="password" type="password" />
          <TextInput source="passwordVerify" type="password" />
          <CheckboxGroupInput
            source="scopes"
            options={{ fullWidth: true }}
            choices={scopeChoices}
            label="API Permissions"
            optionText="text"
            allowEmpty="true"
            optionValue="value"
            />
        </FormTab>
        { user?.scopes?.includes("useradmin:edit_user_secrets") && <FormTab label="User Secrets">
          {
            user?.scopes?.includes("api:tasking") && <Span>API Tasking</Span>
          }
          {
            user?.scopes?.includes("api:tasking") && <TextInput label="Blackwing Token" source="secrets.api.tasking"/>
          }
          {
            user?.scopes?.includes("api:delivery") && <Span>API Delivery</Span>
          }
          {
            user?.scopes?.includes("api:delivery") && <TextInput label="Telluric Username" source="secrets.api.delivery.username"/>
          }
          {
            user?.scopes?.includes("api:delivery") && <TextInput label="Telluric Password" source="secrets.api.delivery.password" type="password" />
          }
          {
            user?.scopes?.includes("service:delivery") && <Span>Delivery Service</Span>
          }
          {
            user?.scopes?.includes("service:delivery") &&  <TextInput label="Destination Bucket" source="secrets.api.deliveryService.destination"/>
          }
          {
            user?.scopes?.includes("service:delivery") &&  <TextInput label="Key Id" source="secrets.api.deliveryService.keyId"/>
          }
          {
            user?.scopes?.includes("service:delivery") &&  <TextInput label="Key Secret" source="secrets.api.deliveryService.keySecret"/>
          }
        </FormTab>}
      </TabbedForm>
    </Edit>
  );
};