import React, { FC } from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  TextInput,
} from 'react-admin';


export const ListComponent: FC = (props) => {
  const postFilters = [
    <TextInput label="Username" source="username" />,
    <TextInput label="Account" source="account" />,
    <TextInput label="Email" source="email" />,
  ];

  return <List {...props} filters={postFilters} perPage={100} sort={{ field: 'account', order: 'DESC' }}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="account" />
      <TextField source="username" />
      <TextField source="email" />
      <ShowButton />
    </Datagrid>
  </List>
};
