import React, {FC} from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectArrayInput,
    BooleanInput,
    useGetList,
} from 'react-admin';
import styled from 'styled-components';


const Row = styled.div`
      display: flex;
      flex-direction: row;
      margin-left: 100px;
      width:100%;
`;

const Divider = styled.div`
  width:25px;
`;

const Span = styled.span`
  width: 250px;
  font-weight:900;
`;

export const CreateComponent:FC = (props) => {
    const { ids: scopes} = useGetList('scopes');
    const scopeChoices: {id:string, value:string, text:string}[] = (scopes as string[])?.map(scope => ({
      id: scope,
      value: scope,
      text: scope.replace("api", "API")
            .replaceAll(":", " ")
            .replaceAll("-", " ")
            .replaceAll("_", " ")
            .split(" ")
            .map(text => `${text.charAt(0).toUpperCase()}${text.slice(1)}`).join(" ")
    })) || [];
    return (
      <Create {...props}>
        <SimpleForm>
          <Row>
            <Span>User Information</Span>
          </Row>
          <Row>
            <TextInput source="account" />
            <Divider />
            <TextInput source="username" />
          </Row>
          <Row>
            <TextInput source="email" type="email" />
            <Divider />
            <BooleanInput source="email_verified" />
          </Row>
          <Row>
            <Span>API Permissions</Span>
          </Row>
          <Row>
            <SelectArrayInput
              source="scopes"
              style={{ width: "200px" }}
              options={{ fullWidth: true }}
              choices={scopeChoices}
              optionText="text"
              allowEmpty="true"
              optionValue="value"
              label="API Permissions"
            />
          </Row>
        </SimpleForm>
      </Create>
    );
};