import React, {useState, useEffect} from 'react';
import { Admin, Resource } from 'react-admin';
import { UserList, UserShow, UserCreate, UserEdit } from './panels/users';
import { ScopesList } from './panels/scopes';
import { dataProvider } from './services/DataProvider';
import { useAuth0 } from "@auth0/auth0-react";
import './App.css';


const App = () => {
  const { isLoading, error, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string|undefined>(undefined);
  useEffect(() => {
    if(isAuthenticated && !token){
      getAccessTokenSilently({
        scope:
          "openid profile email api:useradmin useradmin:list_users useradmin:read_user useradmin:read_user_secrets useradmin:create_user useradmin:edit_user useradmin:edit_user_scopes useradmin:edit_user_secrets useradmin:block_user useradmin:list_scopes",
      }).then(setToken);
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, token]);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
  };
  if(!token){
    return <div>Loading...</div>;
  }
  const options = { user: { authenticated: isAuthenticated, token: token } };
  return (
    <Admin dataProvider={dataProvider(options)}>
      <Resource
        name="users"
        list={UserList}
        show={UserShow}
        create={UserCreate}
        edit={UserEdit}
      />
      <Resource
        name="scopes"
        list={ScopesList}
      />
    </Admin>
  );
}

export default App;
