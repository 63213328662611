import { DataProvider, fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
const httpClient = fetchUtils.fetchJson;
const url = process.env.REACT_APP_API_URL;

const createPassword = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#$%';
  const charactersLength = characters.length;
  for ( var i = 0; i < 20; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};


const getUsers = async ({pagination: {perPage, page}, filter}: {pagination: {perPage:number, page:number}, filter?: {account?: string, username?:string, email?: string}}, options: {user: { authenticated: boolean; token: string }}) => {
  const query = {
    page: page - 1,
    limit: perPage,
    ...(filter || {})
  };
  return await httpClient(`${url}/controlplane/users?${stringify(query)}`, { ...options, credentials: "include", mode:"cors" }).then(
    ({ json }) => {
      return {
      data: json.users.map((user:any) => ({...user, id: user.user_id})),
      total: json.total,
     };
  });
};

const getUser = async (id:string, options: {user: { authenticated: boolean; token: string }}) => {
  return await httpClient(`${url}/controlplane/users/${id}`, { ...options, credentials: "include", mode:"cors" }).then(
    ({ json }) => {
      return {
        id,
        ...json
      };
  });
};

const getScopes = async (options: {user: { authenticated: boolean; token: string }}) => {
  return await httpClient(`${url}/controlplane/scopes`, { ...options, credentials: "include", mode:"cors" }).then(
  ({ json }) => {
    return {
      data: json.scopes.map((scope:{value:string, description:string}) => ({
        ...scope,
        id: scope.value
      })),
      total: json.scopes.length,
    };
  }
);
};

interface CreateUserRequest {
  account: string
  username: string
  email: string
  password: string|undefined
  email_verified: boolean
  scopes: string[]
};

interface EditUserRequest {
  account?: string
  username?: string
  email?: string
  password?: string|undefined
  passwordVerify?: string|undefined
  email_verified?: boolean
  scopes?: string[]
};

const createUser = async (params: CreateUserRequest, options: {user: { authenticated: boolean; token: string }}) => {
  return await httpClient(`${url}/controlplane/users`, { ...options, credentials: "include", mode:"cors", method: "POST", body: JSON.stringify(params) }).then(
    ({ json }) => {
      return {
        data: {...json, id: json.user_id}
     };
  });
};

const editUser = async (id: string, params: EditUserRequest, options: {user: { authenticated: boolean; token: string }}) => {
  return await httpClient(`${url}/controlplane/users/${id}`, { ...options, credentials: "include", mode:"cors", method: "PATCH", body: JSON.stringify(params) }).then(
    ({ json }) => {
      return {
        data: {...json, id: json.user_id}
     };
  });
};


export const dataProvider: (options: {
  user: { authenticated: boolean; token: string };
}) => DataProvider = (options) => ({
  getList: async(resource, params) => {
    switch(resource){
      case "users":
        return await getUsers(params, options);
      case "scopes":
        return await getScopes(options);
      default:
        return {data: [], total: 0}
    }
  },
  getOne: async (resource, params) => {
    switch(resource){
      case "users":
        return {data: await getUser(params.id as string, options)};
      default:
        return {data: undefined}
    }
  },
  getMany: async (resource, params) => ({data: [], total: 0}),
  getManyReference: async (resource, params) => ({data: [], total: 0}),
  create: async (resource, params) => {
    switch(resource){
      case "users":
        const user = {
          password: params.data.password !== "" && params.data.password !== undefined ? params.data.password : createPassword(),
          email_verified: params.data.email_verified || false,
          ...params.data
        } as CreateUserRequest;
        return await createUser(user, options);
      default:
        return {data: undefined}
    }
  },
  update: async (resource, params) => {
    switch(resource){
      case "users":
        if (params.data.verifyPassword !== undefined) delete params.data.verifyPassword;
        if (params.data.password !== undefined) delete params.data.password;
        const toEdit = Object.keys(params.data).reduce((acc, key) => {
          const currentValue = params.data[key];
          const previousValue = params.previousData[key];
          return currentValue !== previousValue ? {
              ...acc,
              [key]: currentValue
            } : acc;
        }, {}) as EditUserRequest;
        console.log({toEdit})
        return await editUser(params.data.id, toEdit, options);
      default:
        return {data: {}}
    }
  },
  updateMany: async (resource, params) => ({}),
  delete: async (resource, params) => ({data: {} as any}),
  deleteMany: async (resource, params) => ({})
})