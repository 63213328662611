import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
  <React.Fragment>
    <Auth0Provider
      domain={process.env.REACT_APP_LOGIN_DOMAIN as string}
      clientId={process.env.REACT_APP_CLIENT_ID as string}
      audience={process.env.REACT_APP_AUDIENCE as string}
      scope="openid profile email api:useradmin useradmin:list_users useradmin:read_user useradmin:read_user_secrets useradmin:create_user useradmin:edit_user useradmin:edit_user_scopes useradmin:edit_user_secrets useradmin:block_user useradmin:list_scopes"
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
